
export class CpmsRoutesConstants {

    public static CPMS_FLOORS_ROUTE = '/cpms/floors';
    public static CPMS_FLOORS_EDIT_ROUTE = '/cpms/floors/edit';
    public static CPMS_FLOORS_LIVE_ROUTE = '/cpms/floors/live';
    public static CPMS_MANAGMENT_ROUTE = '/cpms/management';
    public static CPMS_ASSETS_TRASH_ROUTE = '/cpms/management/asset-manager/trash';
    public static CPMS_ASSETS_MANAGER_ROUTE = '/cpms/management/asset-manager';
    public static CPMS_DEVICE_MANAGER_ROUTE = '/cpms/management/device-manager';
    public static SUPPORT_ROUTE = 'https://support.pointgrab.com';
}
